<!--
 * @Author: leo
 * @Description: 跳转页面
-->
<template>
    <div class="jump-page">
        <div class="jump-box">
            <img src="../assets/img/jump.png" />
            <p>提交成功</p>
            <div>网页跳转至首页…等待<span>{{ countDown }}</span>秒</div>
        </div>
    </div>
</template>

<script lang="javascript">

export default {
    name: "JumpPage",
    components: {},
    data() {
        return {
            countDown: 3
        };
    },
    created() {
        this.countDownFun()
    },
    methods: {
        countDownFun() {
            console.log('this.$route.query.order_id',this.$route.params);
            let that = this,
                time = setInterval(function () {
                    that.countDown = that.countDown - 1
                    if (that.countDown === 0) {
                        clearInterval(time)
                           // window.location.href = `http://localhost:8080/#/HandleProgress`
                        // window.location.href = `${process.env.VUE_APP_CLIENT_URL}/#/ProjectList`
                        // 获取access_token
                        that.$router.push({
                            name: "TransferDetails",
                            params: {
                                id: Number(that.$route.params.order_id),
                            },
                        })
                    }
                }, 1000);
        }
    },
};
</script>

<style lang="less" scoped>
.jump-box {
    margin-top: 190px;
    text-align: center;
    font-size: 14px;
    height: 530px;
    img {
        width: 200px;
        height: 200px;
    }

    p {
        margin: 6px 0 4px;
        color: #409EFF;
    }

    div {
        color: #303133;

        span {
            color: #409EFF;
        }
    }
}
</style>
